.custom-select__input {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  border: none;
  padding: 10px 14px;
  border-radius: 10px;
  width: calc(100% - 28px);
  line-height: 105%;
  outline: none;
}