.nav-menu__button_active {
  text-decoration: none;
  position: relative;
  color: #FFFFFF;
  font-size: 19px;
  font-weight: 400;
  padding: 9px 0 9px 54px;
  background: #AFAFB1;
  margin: 0 0 8px 0;
}

.nav-menu__button_active:nth-of-type(1)::before {
  content: "";
  position: absolute;
  background: url(../../../../images/catalog.svg);
  width: 24px;
  height: 24px;
  left: 20px;
  top: 7px;
  filter: brightness(10000%);
}

.nav-menu__button_active:nth-of-type(2)::before {
  content: "";
  position: absolute;
  background: url(../../../../images/Star_duotone.svg);
  width: 24px;
  height: 24px;
  left: 20px;
  top: 7px;
  filter: brightness(10000%);
}

.nav-menu__button_active:nth-of-type(3)::before {
  content: "";
  position: absolute;
  background: url(../../../../images/direct-inbox.svg);
  width: 24px;
  height: 24px;
  left: 20px;
  top: 7px;
  filter: brightness(10000%);
}

.nav-menu__button_active:nth-of-type(4)::before {
  content: "";
  position: absolute;
  background: url(../../../../images/box-search.svg);
  width: 24px;
  height: 24px;
  left: 20px;
  top: 7px;
  filter: brightness(10000%);
}

.nav-menu__button_active:nth-of-type(5)::before {
  content: "";
  position: absolute;
  background: url(../../../../images/bag.svg);
  width: 24px;
  height: 24px;
  left: 20px;
  top: 7px;
  filter: brightness(10000%);
}

.nav-menu__button_active:nth-of-type(6)::before {
  content: "";
  position: absolute;
  background: url(../../../../images/Setting_line_duotone_line.svg);
  width: 24px;
  height: 24px;
  left: 20px;
  top: 7px;
  filter: brightness(10000%);
}