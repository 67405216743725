.nav-menu__button {
	text-decoration: none;
	display: flex;
	position: relative;
	color: #87888a;
	font-size: 19px;
	font-weight: 400;
	padding: 9px 0 9px 54px;
}

.nav-menu__button:nth-of-type(1)::before {
	content: '';
	position: absolute;
	background: url(../../../images/catalog.svg);
	width: 24px;
	height: 24px;
	left: 20px;
	top: 8px;
}

.nav-menu__button:nth-of-type(2)::before {
	content: '';
	position: absolute;
	background: url(../../../images/Star_duotone.svg);
	width: 24px;
	height: 24px;
	left: 20px;
	top: 10px;
}

.nav-menu__button:nth-of-type(3)::before {
	content: '';
	position: absolute;
	background: url(../../../images/direct-inbox.svg);
	width: 24px;
	height: 24px;
	left: 20px;
	top: 9px;
}

.nav-menu__button:nth-of-type(4)::before {
	content: '';
	position: absolute;
	background: url(../../../images/box-search.svg);
	width: 24px;
	height: 24px;
	left: 20px;
	top: 9px;
}

.nav-menu__button:nth-of-type(5)::before {
	content: '';
	position: absolute;
	background: url(../../../images/bag.svg);
	width: 24px;
	height: 24px;
	left: 20px;
	top: 9px;
}

.nav-menu__button:nth-of-type(6)::before {
	content: '';
	position: absolute;
	background: url(../../../images/Setting_line_duotone_line.svg);
	width: 24px;
	height: 24px;
	left: 20px;
	top: 9px;
}
