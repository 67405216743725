.logist__total {
  margin: 0;
  color: #2886EB;
  font-size: 14px;
  font-weight: 500;
}
.logist__total::after {
  content: ' $';
  color: #A4B4DE;
  font-size: 14px;
  font-weight: 400;
}