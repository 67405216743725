.filter-popup__radio-label {
  position: absolute;
  width: 200%;
  height: 200%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-popup__radio-label:hover {
  cursor: pointer;
}