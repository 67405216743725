.cart-check-box__label {
  cursor: pointer;
  transition: opacity 0.3s;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background: rgba(40, 134, 235, 0.10);
  display: inline-block;
}
.cart-check-box__label:hover {
  opacity: 0.8;
}