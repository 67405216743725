@keyframes closeModalWithForm {
  0% {
    visibility: visible;
    opacity: 1;
    display: flex;
  }
  100% {
    visibility: visible;
    opacity: 0;
  }
}

.modal_hidden {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.575);
  z-index: 6;
  display: none;
  justify-content: center;
  align-items: center;
  animation: closeModalWithForm 0.2s ease;
  visibility: hidden;
}