.confirm-button {
  border-radius: 10px;
  background: #007AFF !important;
  padding: 12px 24px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
}

.confirm-button:hover {
  opacity: 0.8;
}