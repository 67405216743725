.cansel-button {
  border-radius: 10px;
  background: rgba(19, 19, 20, 0.03);
  padding: 12px 24px;
  color: #909090;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
}

.cansel-button:hover {
  background: rgba(19, 19, 20, 0.08);
}