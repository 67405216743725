.interaction__filter-button {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  line-height: 105%;
  position: relative;
  padding: 0 0 0 29px;
  margin: 0 10px;
}

.interaction__filter-button::before {
  content: '';
  position: absolute;
  background: url(../../../images/Filter_alt.svg);
  width: 24px;
  height: 24px;
  left: 0;
  top: -3px;
}