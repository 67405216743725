.counter__input {
  text-align: center;
  width: 100%;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  padding: 0 5px;
}
.counter__input::-webkit-outer-spin-button,
.counter__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}