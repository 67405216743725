.ineraction__default-button_type_sort {
  padding-left: 49px;
  padding-right: 15px;
}

.ineraction__default-button_type_sort::before {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../../../../images/Sort_list_light.svg);
  position: absolute;
  left: 15px;
  top: 6px
}