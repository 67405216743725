.filter-popup__radio-button {
  border-radius: 10px;
  border: 1.5px solid #2886EB;
  position: relative;
  height: 33px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  box-sizing: border-box;
}

.filter-popup__radio-button:nth-child(2) {
  margin: 0 10px 0 10px;
}

.filter-popup__radio-button:last-child {
  margin-right: 0;
}