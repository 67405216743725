.product-page__image-list {
  display: flex;
  flex-direction: column;
  height: 632px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  border-radius: 15px;
  border: 1px solid rgba(40, 134, 235, 0.10);
  margin: 0 20px 0 0;
  padding: 55px 0px;
  justify-content: space-between;
  gap: 10px 0;
}
.product-page__image-list::-webkit-scrollbar {
  width: 6px;
}

.product-page__image-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #B5B5B5;
}

.product-page__image-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #ebebec;
}