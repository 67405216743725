.custom-select__option {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  padding: 11px 12px;
  cursor: pointer;
  margin: 0;
}
.custom-select__option:hover {
  background: #eeeeee52;
}