.counter__button {
  padding: 10px 15px;
  transition: background 0.3s;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
}

.counter__button:hover {
  background: rgba(19, 19, 20, 0.103);
}