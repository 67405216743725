@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./vendor/normalize.css);
@import url(./vendor/fonts.css);

@import url(./blocks/zero-button/zero-button.css);
@import url(./blocks/zero-input/zero-input.css);
@import url(./blocks/table-container/table-container.css);
@import url(./blocks/cansel-button/cansel-button.css);
@import url(./blocks/confirm-button/confirm-button.css);

@import url(./blocks/page/page.css);
@import url(./blocks/page/__wrapper/page__wrapper.css);

@import url(./blocks/nav-menu/nav-menu.css);
@import url(./blocks/nav-menu/__logo/nav-menu__logo.css);
@import url(./blocks/nav-menu/__button/nav-menu__button.css);
@import url(./blocks/nav-menu/__button/_active/nav-menu__button_active.css);
@import url(./blocks/nav-menu/__button-list/nav-menu__button-list.css);
@import url(./blocks/nav-menu/__category-button/nav-menu__category-button.css);
@import url(./blocks/nav-menu/__category-container/nav-menu__category-container.css);
@import url(./blocks/nav-menu/__category-container/_open/nav-menu__category-container_open.css);
@import url(./blocks/nav-menu/__category-button/_active/nav-menu__category-button_active.css);

@import url(./blocks/main-section/main-section.css);
@import url(./blocks/main-section/__container/main-section__container.css);

@import url(./blocks/header/header.css);
@import url(./blocks/header/__description/header__description.css);
@import url(./blocks/header/__spoiler-button/header__spoiler-button.css);
@import url(./blocks/header/__user-caption/header__user-caption.css);
@import url(./blocks/header/__user-data/header__user-data.css);
@import url(./blocks/header/__user-icon/header__user-icon.css);
@import url(./blocks/header/__user-name/header__user-name.css);
@import url(./blocks/header/__user-panel/header__user-panel.css);
@import url(./blocks/header/__path/header__path.css);
@import url(./blocks/header/___previous-path/header__previous-path.css);
@import url(./blocks/header/__real-path/header__real-path.css);
@import url(./blocks/header/__arrow/header__arrow.css);

@import url(./blocks/main/main.css);
@import url(./blocks/main/__title/main__title.css);
@import url(./blocks/main/__flex-wrapper/main__flex-wrapper.css);
@import url(./blocks/main/__container/main__container.css);

@import url(./blocks/table/table.css);
@import url(./blocks/table/__content/table__content.css);
@import url(./blocks/table/__row/table__row.css);
@import url(./blocks/table/__row/_grid/table__row_grid_attributes-category.css);
@import url(./blocks/table/__row/_grid/table__row_grid_attributes.css);
@import url(./blocks/table/__row/_grid/table__row_grid_balances.css);
@import url(./blocks/table/__row/_grid/table__row_grid_inventory-control.css);
@import url(./blocks/table/__row/_grid/table__row_grid_products-category.css);
@import url(./blocks/table/__row/_grid/table__row_grid_system-users.css);
@import url(./blocks/table/__row/_grid/table__row_grid_relocation.css);
@import url(./blocks/table/__title/table__title.css);
@import url(./blocks/table/__body/table__body.css);
@import url(./blocks/table/__head/table__head.css);
@import url(./blocks/table/__self-item/table__self-item.css);
@import url(./blocks/table/__status-item/table__status-item.css);

@import url(./blocks/interaction/interaction.css);
@import url(./blocks/interaction/__close-button/interaction__close-button.css);
@import url(./blocks/interaction/__search-button/interaction__search-button.css);
@import url(./blocks/interaction/__search-line/interaction__search-line.css);
@import url(./blocks/interaction/__search/interaction__search.css);
@import url(./blocks/interaction/__filter-button/interaction__filter-button.css);
@import url(./blocks/interaction/__default-button/ineraction__default-button.css);
@import url(./blocks/interaction/__default-button/_type/ineraction__default-button_type_filter.css);
@import url(./blocks/interaction/__default-button/_type/ineraction__default-button_type_sort.css);

@import url(./blocks/filter-popup/filter-popup.css);
@import url(./blocks/filter-popup/__section/filter-popup__section.css);
@import url(./blocks/filter-popup/__title/filter-popup__title.css);
@import url(./blocks/filter-popup/__title/_type/filter-popup__title_type_filters.css);
@import url(./blocks/filter-popup/__title/_type/filter-popup__title_type_sorts.css);
@import url(./blocks/filter-popup/_state/filter-popup_state_fade.css);
@import url(./blocks/filter-popup/__close-button/filter-popup__close-button.css);
@import url(./blocks/filter-popup/__container/filter-popup__container.css);
@import url(./blocks/filter-popup/__input-close-button/filter-popup__input-close-button.css);
@import url(./blocks/filter-popup/__input/filter-popup__input.css);
@import url(./blocks/filter-popup/__inputs/filter-popup__inputs.css);
@import url(./blocks/filter-popup/__subtitle/filter-popup__subtitle.css);
@import url(./blocks/filter-popup/__flex-wrapper/filter-popup__flex-wrapper.css);
@import url(./blocks/filter-popup/__input-line/filter-popup__input-line.css);
@import url(./blocks/filter-popup/__select/filter-popup__select.css);
@import url(./blocks/filter-popup/__radio/filter-popup__radio.css);
@import url(./blocks/filter-popup/__radio-label/filter-popup__radio-label.css);
@import url(./blocks/filter-popup/__radio-button/filter-popup__radio-button.css);
@import url(./blocks/filter-popup/__selects-wrapper/filter-popup__selects-wrapper.css);
@import url(./blocks/filter-popup/__sort-wrapper/filter-popup__sort-wrapper.css);
@import url(./blocks/filter-popup/__sort-name/filter-popup__sort-name.css);
@import url(./blocks/filter-popup/__selects-arrow/filter-popup__selects-arrow.css);
@import url(./blocks/filter-popup/__select/_with-arrow/filter-popup__select_with-arrow.css);
@import url(./blocks/filter-popup/__button-image/filter-popup__button-image.css);
@import url(./blocks/filter-popup/__sort-button/filter-popup__sort-button.css);

@import url(./blocks/cards-list/cards-list.css);
@import url(./blocks/cards-list/__card-image/cards-list__card-image.css);
@import url(./blocks/cards-list/__card-caption/cards-list__card-caption.css);
@import url(./blocks/cards-list/__card-name/cards-list__card-name.css);
@import url(./blocks/cards-list/__card/cards-list__card.css);
@import url(./blocks/cards-list/__grid-wrapper/cards-list__grid-wrapper.css);
@import url(./blocks/cards-list/__image-wrapper/card-list__image-wrapper.css);

@import url(./blocks/categorys/categorys.css);
@import url(./blocks/categorys/__grid-wrapper/categorys__grid-wrapper.css);
@import url(./blocks/categorys/__item/categorys__item.css);
@import url(./blocks/categorys/__section/categorys__section.css);
@import url(./blocks/categorys/__title/categorys__title.css);
@import url(./blocks/categorys/_opened/categorys_opened.css);
@import url(./blocks/categorys/__search/categorys__search.css);
@import url(./blocks/categorys/__search-line/categorys__search-line.css);
@import url(./blocks/categorys/__search-button/categorys__search-button.css);

@import url(./blocks/logist/logist.css);
@import url(./blocks/logist/__all-price/logist__all-price.css);
@import url(./blocks/logist/__buttons-container/logist__buttons-container.css);
@import url(./blocks/logist/__card-key/logist__card-key.css);
@import url(./blocks/logist/__card-value/logist__card-value.css);
@import url(./blocks/logist/__card/logist__card.css);
@import url(./blocks/logist/__column-name/logist__column-name.css);
@import url(./blocks/logist/__column-name/_type/logist__column-name_type_conversion.css);
@import url(./blocks/logist/__column-name/_type/logist__column-name_type_customs-cleared.css);
@import url(./blocks/logist/__column-name/_type/logist__column-name_type_on-customs.css);
@import url(./blocks/logist/__column-name/_type/logist__column-name_type_on-road.css);
@import url(./blocks/logist/__column/logist__column.css);
@import url(./blocks/logist/__flex-wrapper/logist__flex-wrapper.css);
@import url(./blocks/logist/__move-button/logist__move-button.css);
@import url(./blocks/logist/__total/logist__total.css);
@import url(./blocks/logist/__card/_type/logist__card_type_conversion.css);
@import url(./blocks/logist/__card/_type/logist__card_type_customs-cleared.css);
@import url(./blocks/logist/__card/_type/logist__card_type_on-customs.css);
@import url(./blocks/logist/__card/_type/logist__card_type_on-road.css);
@import url(./blocks/logist/__cards-container/logist__cards-container.css);
@import url(./blocks/logist/__new-oreder/logist__new-oreder.css);
@import url(./blocks/logist/__move-button/_hidden/logist__move-button_hidden.css);

@import url(./blocks/custom-select/__option/custom-select__option.css);
@import url(./blocks/custom-select/custom-select.css);
@import url(./blocks/custom-select/__input/custom-select__input.css);
@import url(./blocks/custom-select/__items/custom-select__items.css);
@import url(./blocks/custom-select/__dropped-list-button/custom-select__dropped-list-button.css);
@import url(./blocks/custom-select/__dropped-list-button/_closed/custom-select__dropped-list-button_closed.css);
@import url(./blocks/custom-select/__items/_hidden/custom-select__items_hidden.css);
@import url(./blocks/custom-select/__flex-wrapper/custom-select__flex-wrapper.css);

@import url(./blocks/empty-cart/empty-cart.css);
@import url(./blocks/empty-cart/__button/empty-cart__button.css);
@import url(./blocks/empty-cart/__image/empty-cart__image.css);
@import url(./blocks/empty-cart/__text/empty-cart__text.css);

@import url(./blocks/cart-info/cart-info.css);
@import url(./blocks/cart-info/__flex-wrapper/cart-info__flex-wrapper.css);
@import url(./blocks/cart-info/__price/cart-info__price.css);
@import url(./blocks/cart-info/__title/cart-info__title.css);
@import url(./blocks/cart-info/__total/cart-info__total.css);
@import url(./blocks/cart-info/__items/cart-info__items.css);

@import url(./blocks/counter/counter.css);
@import url(./blocks/counter/__button/counter__button.css);
@import url(./blocks/counter/__input/counter__input.css);

@import url(./blocks/cart-check-box/cart-check-box.css);
@import url(./blocks/cart-check-box/__input/cart-check-box__input.css);
@import url(./blocks/cart-check-box/__label/cart-check-box__label.css);

@import url(./blocks/cart-item/cart-item.css);
@import url(./blocks/cart-item/__caption/cart-item__caption.css);
@import url(./blocks/cart-item/__container/cart-item__container.css);
@import url(./blocks/cart-item/__cost/cart-item__cost.css);
@import url(./blocks/cart-item/__counter-name/cart-item__counter-name.css);
@import url(./blocks/cart-item/__delete-button/cart-item__delete-button.css);
@import url(./blocks/cart-item/__flex-wrapper/cart-item__flex-wrapper.css);
@import url(./blocks/cart-item/__id/cart-item__id.css);
@import url(./blocks/cart-item/__image/cart-item__image.css);
@import url(./blocks/cart-item/__name/cart-item__name.css);
@import url(./blocks/cart-item/__selected-positions/cart-item__selected-positions.css);
@import url(./blocks/cart-item/__total-price/cart-item__total-price.css);
@import url(./blocks/cart-item/__flex-column-wrapper/cart-item__flex-column-wrapper.css);
@import url(./blocks/cart-item/__container-grow/cart-item__container-grow.css);
@import url(./blocks/cart-item/__create-button/cart-item__create-button.css);

@import url(./blocks/cart/cart.css);
@import url(./blocks/cart/__delete-button/cart__delete-button.css);
@import url(./blocks/cart/__label/cart__label.css);
@import url(./blocks/cart/__panel/cart__panel.css);

@import url(./blocks/physical-cart/physical-cart.css);
@import url(./blocks/physical-cart/__create-button/physical-cart__create-button.css);

@import url(./blocks/physical-cart-item/physical-cart-item.css);
@import url(./blocks/physical-cart-item/__text/physical-cart-item__text.css);

@import url(./blocks/product-page/product-page.css);
@import url(./blocks/product-page/__characteristics-card/product-page__characteristics-card.css);
@import url(./blocks/product-page/__characteristics-title/product-page__characteristics-title.css);
@import url(./blocks/product-page/__characteristics-type/product-page__characteristics-type.css);
@import url(./blocks/product-page/__characteristics-value/product-page__characteristics-value.css);
@import url(./blocks/product-page/__characteristics/product-page__characteristics.css);
@import url(./blocks/product-page/__description-title/product-page__description-title.css);
@import url(./blocks/product-page/__description/product-page__description.css);
@import url(./blocks/product-page/__flex-wrapper/product-page__flex-wrapper.css);
@import url(./blocks/product-page/__image-list/product-page__image-list.css);
@import url(./blocks/product-page/__image-swiper/product-page__image-swiper.css);
@import url(./blocks/product-page/__main-text/product-page__main-text.css);
@import url(./blocks/product-page/__title/product-page__title.css);
@import url(./blocks/product-page/__viewing-image/product-page__viewing-image.css);
@import url(./blocks/product-page/__view-window/product-page__view-window.css);
@import url(./blocks/product-page/__image-from-list/product-page__image-from-list.css);

@import url(./blocks/pagination/pagination.css);
@import url(./blocks/pagination/__move-button/pagination__move-button.css);
@import url(./blocks/pagination/__number/pagination__number.css);
@import url(./blocks/pagination/__number/_active/pagination__number_active.css);

@import url(./blocks/empty-content/empty-content.css);
@import url(./blocks/empty-content/__image/empty-content__image.css);
@import url(./blocks/empty-content/__text/empty-content__text.css);

@import url(./blocks/preloader/__item/preloader__item.css);
@import url(./blocks/preloader/preloader.css);

@import url(./blocks/modal/__close-button/modal__close-button.css);
@import url(./blocks/modal/__container/modal__container.css);
@import url(./blocks/modal/__title/modal__title.css);
@import url(./blocks/modal/_hidden/modal_hidden.css);
@import url(./blocks/modal/modal.css);

@import url(./blocks/attributes-modal/attributes-modal.css);
@import url(./blocks/attributes-modal/__container/attributes-modal__container.css);
@import url(./blocks/attributes-modal/__field-name/attributes-modal__field-name.css);
@import url(./blocks/attributes-modal/__input/attributes-modal__input.css);
@import url(./blocks/attributes-modal/__select/attributes-modal__select.css);
@import url(./blocks/attributes-modal/__flex-wrapper/attributes-modal__flex-wrapper.css);

@import url(./blocks/modal_creating_logistics_order/modal_creating_logistics_order.css);

.animation-none{
    animation: none !important;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}