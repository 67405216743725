.categorys__item {
  text-decoration: none;
  color: #757575;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0 0 0;
  transition: opacity 0.3s;
}
.categorys__item:hover {
  opacity: 0.7;
}