@keyframes openMenu {
  0% {
    left: -100%;
  }
  100% {
    left: 253px;
  }
}

.categorys_opened {
  left: 253px;
  animation: openMenu 0.3s;
}