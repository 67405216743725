@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader__item {
  display: block;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 4px solid #2886EB;
  border-top: 0px solid white;
  border-right: 0px solid white;
  animation: spinner 1s linear infinite;
  border-width: 6px;
}