.cart-item__create-button {
  padding: 11px 24px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 105%;
  background: #2886EB;
  border-radius: 10px;
  position: relative;
  width: 124px;
  display: block;
  transition: opacity 0.3s;
}
.cart-item__create-button:hover {
  opacity: 0.8;
}