@keyframes closePopup {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 0;
  }
}

.filter-popup_state_fade {
  animation: closePopup 0.2s ease;
  visibility: hidden;
}