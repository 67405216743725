.filter-popup__select {
  padding: 8px 15px 8px 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  background: rgba(19, 19, 20, 0.03) url(../../../images/arrow.svg) right 10px top 10px / 11px 11px no-repeat;
  appearance:none;
  color: #909090;
  position: relative;
  height: 33px;
  width: 165px;
  margin: 0 13px 0 0;
}