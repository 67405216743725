.cart__delete-button {
  color: #414141;
  font-size: 16px;
  font-weight: 500;
  line-height: 105%;
  position: relative;
  padding: 0 0 0 33px;
  transition: opacity 0.3s;
}
.cart__delete-button::before {
  content: "";
  left: 0;
  top: -6px;
  position: absolute;
  height: 28px;
  width: 28px;
  background: url(../../../images/closeRing.svg) center no-repeat;
}
.cart__delete-button:hover {
  opacity: 0.8;
}