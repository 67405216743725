@keyframes closeMenu {
  0% {
    left: 253px;
  }
  100% {
    left: -100%;
  }
}

.categorys {
  position: absolute;
  width: 735px;
  height: calc(100% - 70px);
  border-right: 1px solid #E6E6E6;
  background: #FFF;
  padding: 35px;
  left: -100%;
  overflow: auto;
  animation: closeMenu 0.3s;
}