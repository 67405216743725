.cart-item__flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px 0;
  gap: 0 15px;
}
.cart-item__flex-wrapper:first-of-type {
  margin: 0 0 20px 0;
}
.cart-item__flex-wrapper:last-of-type {
  margin: 0;
}