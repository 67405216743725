@keyframes showSpoiler {
  0% {
    max-height: 0;
    display: none;
  }

  100% {
    max-height: 180px;
    display: flex;
  }
}

.nav-menu__category-container_open {
  animation: showSpoiler 0.3s linear;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}