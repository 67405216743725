.interaction__search-line {
  display: block;
  margin: 0 4px 0 6px;
  flex: 1 0 auto;
  padding: 8px 10px;
  color: #929292;
  font-size: 16px;
  font-weight: 400;
  line-height: 105%;
  outline: none;
  border: none;
  width: calc(100% - 100px);
  background: none;
}