.pagination__move-button {
  width: 18px;
  height: 18px;
  background: url(../../../images/ArrowPagin.svg);
  transition: opacity 0.3s;
}
.pagination__move-button:last-of-type {
  transform: rotate(180deg);
}
.pagination__move-button:hover {
  opacity: 0.8;
}