.logist__move-button {
  background: url(../../../images/left-move.svg) center no-repeat;
  width: 24px;
  height: 24px;
  transition: opacity 0.3s;
}
.logist__move-button:last-of-type {
  background: url(../../../images/right-move.svg) center no-repeat;
}

.logist__move-button:hover {
  opacity: 0.7;
}