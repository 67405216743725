.filter-popup__sort-name {
  text-align: center;
  width: 163px;
  border-radius: 10px;
  background: rgba(40, 134, 235, 0.10);
  padding: 10px 24px;
  box-sizing: border-box;
  margin: 0 10px;
  color: #2886EB;
  font-size: 14px;
  font-weight: 500;
}