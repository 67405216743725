.table__self-item {
  color:  #FFF;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 20px;
  width: 50px;
  text-align: center;
  box-sizing: border-box;
}