.filter-popup__title_type_sorts::before {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../../../../images/Sort.svg) center no-repeat;
  position: absolute;
  left: 0;
  top: -3px;
}

.filter-popup__title_type_sorts {
  margin: 0 0 10px 0;
}