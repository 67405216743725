@keyframes openModalWithForm {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.575);
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: openModalWithForm 0.2s ease;
  visibility: visible;
}