.table__row {
  display: grid;
  margin: 20px 0 0 0;
  column-gap: 30px;
  padding: 10px;
  align-items: center;
  transition: background 0.2s;
}

thead > .table__row {
  margin: 0;
  border-bottom: 1px solid #0000001f;
}

thead > .table__row:hover {
  background: none;
  cursor: default;
  border-radius: 0;
}

.table__row:hover {
  background: #1313140a;
  border-radius: 5px;
  cursor: pointer;
}