.attributes-modal__input {
  border-radius: 10px;
  background: #1313140a;
  padding: 8px 10px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
}

.attributes-modal__input::placeholder {
  color: #B5B5B5;
}