@keyframes hideSpoiler {
  0% {
    display: flex;
    max-height: 170px;
  }

  100% {
    display: flex;
    max-height: 0px;
  }
}


.nav-menu__category-container {
  animation: hideSpoiler 0.3s ease-out;
  flex-direction: column;
  display: none;
  overflow: hidden;
}