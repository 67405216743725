.physical-cart-item__text {
  color: #007AFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 105%;
  margin: 0;
  position: relative;
  padding: 0 0 0 29px;
}
.physical-cart-item__text:nth-of-type(2) {
  margin: 12px 0;
}
.physical-cart-item__text:nth-of-type(1)::before {
  content: "";
  background: url(../../../images/Box_open.svg) center no-repeat;
  left: 0;
  top: -5px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.physical-cart-item__text:nth-of-type(2)::before {
  content: "";
  background: url(../../../images/credit_card.svg) center no-repeat;
  left: 0;
  top: -5px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.physical-cart-item__text:nth-of-type(3)::before {
  content: "";
  background: url(../../../images/Boxes_light.svg) center no-repeat;
  left: 0;
  top: -5px;
  width: 24px;
  height: 24px;
  position: absolute;
}