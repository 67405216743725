.empty-cart__button {
  color: #007AFF;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 24px;
  border-radius: 10px;
  background: rgba(40, 134, 235, 0.10);
  transition: opacity 0.3s;
}
.empty-cart__button:hover {
  opacity: 0.8;
}