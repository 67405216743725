.filter-popup__input-line {
  max-width: calc(100% - 26px);
  background: none;
  border: none;
  color: #909090;
  font-size: 14px;
  font-weight: 500;
  outline: none;
}

.filter-popup__input-line::placeholder {
  color: #B5B5B5;
}

.filter-popup__input-line[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: none;
}