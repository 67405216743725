.logist__new-oreder {
  border-radius: 8px;
  border: 1px dashed #AFCEEB;
  height: 74px;
  width: 100%;
  margin: 15px 0;
  position: relative;
  transition: opacity 0.3s;
}

.logist__new-oreder::after {
  content: 'новый заказ +';
  max-width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #7C8CB8;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 100%;
}

.logist__new-oreder:hover {
  opacity: 0.7;
}