.add-order-logist {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.575);
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 40px;
}
.modal-content-logist {
  background: #ffffff;
  max-width: 605px;
  border-radius: 25px;
  position: relative;
  padding: 40px;
}

.modal__title {
  font-size: 22px;
  margin-bottom: 40px;
}

/*  */

.modal__info-order {
  display: flex;
}

.box {
  display: flex;
  flex-direction: column;
  max-width: 185px;
}

.modal__supplier-selection {
  display: flex;
  flex-direction: column;
  max-width: 185px;
  margin-bottom: 35px;
}

.modal__headline-overall {
  color: #2886eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
  margin-bottom: 12.5px;
}
.modal__input-overall {
  background: rgba(19, 19, 20, 0.03);
  border-radius: 8px;
  padding: 8px 10px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #919191;
}

.modal__close-btn {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  background-image: url("../../images/Close.svg");
}

.select-documents-btn {
  background: rgba(19, 19, 20, 0.03) url("../../images/arrow.svg") right 15px top 10px / 11px 11px no-repeat;

  /* background: rgba(19, 19, 20, 0.03); */
  border-radius: 8px;
  padding: 8px 10px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #919191;
  cursor: pointer;
  max-width: 185px;
  width: 100%;
  text-align: start;
}

/*  */
.modal__add-product-box {
  display: flex;
  margin-bottom: 35px;
}

.select-product-btn {
  background: rgba(19, 19, 20, 0.03) url("../../images/arrow.svg") right 15px top 10px / 11px 11px no-repeat;
  border-radius: 8px;
  padding: 8px 10px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #919191;
  cursor: pointer;
  max-width: 185px;
  width: 185px;
  text-align: start;
}
.stepper {
  margin-left: 15px;
  background: rgba(19, 19, 20, 0.03);
  border-radius: 8px;
  padding: 8px 10px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #919191;
  cursor: pointer;
  /* width: 105px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper__btn--up {
  background-image: url("../../images/plus.svg");
  width: 10px;
  height: 11px;
}
.stepper__btn--down {
  background-image: url("../../images/minus.svg");
  width: 10px;
  height: 1.99px;
}
.stepper__input input {
  background-color: transparent;
  border: none;
  color: #919191;
  outline: none;
}

.stepper__input {
  margin-left: 10px;
  margin-right: 10px;
}

/*  */
.box-input-price {
  margin-left: 15px;
  margin-right: 15px;
}

.modal__input-price {
  background: rgba(19, 19, 20, 0.03);
  border-radius: 8px;
  padding: 8px 10px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #919191;
  cursor: pointer;
  width: 85px;
  text-align: start;
}

.add_product-disabled {
  padding: 8px 15px;
  background: #a1c2ff;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: not-allowed;
  border: none;
}

/*  */

.service-box {
  display: flex;
  margin-bottom: 12px;
}
.modal__list-product {
  margin-bottom: 35px;
}

.modal__service-info {
  color: #6f6f6f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 15px;
  width: 185px;
}

.modal__last-price {
  color: #2886eb;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/*  */

.modal__document-box {
  margin-bottom: 35px;
}

.box-document-add {
  display: flex;
  margin-bottom: 15px;
  position: relative;
}

.box-document-button {
  display: flex;
  align-items: center;
  background: #13131408;
  border-radius: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 15px;
  width: 148px;
  cursor: pointer;
}

.document-btn {
  background-color: transparent;
  outline: none;
  border: none;
  color: #b5b5b5;
  padding-right: 6px;
  padding-left: 6px;
}

.add-document-disabled {
  padding: 8px 15px;
  background: #a1c2ff;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: not-allowed;
  border: none;
}

.box-added-documents {
  display: flex;
  flex-wrap: wrap;
}

.document-item {
  border-radius: 10px;
  border: 1px dashed rgba(29, 93, 235, 0.2);
  padding: 8px 45px 8px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.document-list-header {
  color: #404040;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.document-status {
  display: flex;
  justify-content: start;
  align-items: center;
}

/* .document-status p {
  margin-left: 5px;
  color: #2886eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */

/*  */

.box-btn {
  display: flex;
  justify-content: end;
}

.cancel-modal-btn {
  border-radius: 10px;
  padding: 10px 24px;
  border: none;
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 25px;
  cursor: pointer;
}

.save-modal-btn {
  border-radius: 10px;
  padding: 10px 24px;
  background: #007aff;
  border: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

/*  */

.select-providers {
  position: relative;
}

.select-options-logistics {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 400px;
  max-height: 280px;
  overflow-y: auto;
  top: 40px;
  background: white;
  box-shadow: 0 4px 12px rgb(78 137 253 / 20%);
  border-radius: 10px;
  z-index: 12;
}

.select-list-logistics {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select-item-logistics {
  margin: 4px;
  white-space: nowrap;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  border-radius: 8px;
  color: #8e9ab9;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
}

.select-product {
  position: relative;
}

.search-options {
  position: relative;
  margin: 4px;
}

.search-options svg {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}
.search-options input {
  max-width: 120px;
  background: #f7fbff;
  border-radius: 5px;
  padding: 8px 30px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #a1b6c9;
  outline: none;
  border: none;
}

/*  */

.select-options-product {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 400px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 40px;
  background: white;
  box-shadow: 0 4px 12px rgb(78 137 253 / 20%);
  border-radius: 10px;
  z-index: 11;
}
.select-list-product {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select-item-product {
  margin: 4px;
  white-space: nowrap;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  border-radius: 8px;
  color: #8e9ab9;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
}

.document-options {
  position: absolute;
  left: 0;
  right: 0;
  max-width: 260px;
  max-height: 130px;
  overflow-y: auto;
  top: 40px;
  background: white;
  box-shadow: 0 4px 12px rgb(78 137 253 / 20%);
  border-radius: 10px;
  z-index: 5;
}

.select-document-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select-item-document {
  display: flex;
  align-items: center;
  margin: 4px;
  white-space: nowrap;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  border-radius: 8px;
  color: #8e9ab9;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
}
.select-item-document svg {
  margin-left: 10px;
}
