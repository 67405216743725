@keyframes openPopup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.filter-popup {
  right: 0px;
  top: 0px;
  padding: 35px 25px;
  width: 400px;
  height: calc(70%);
  border-radius: 10px;
  border: 1px solid #EBEBEC;
  background: #FFFF;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: absolute;
  z-index: 5;
  box-sizing: border-box;
  animation: openPopup 0.2s ease;
  overflow-y: auto;
  overflow-x: hidden;
}

.filter-popup::-webkit-scrollbar {
  width: 6px;
}

.filter-popup::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #B5B5B5;
}

.filter-popup::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #ebebec;
}