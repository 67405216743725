.physical-cart__create-button {
  text-align: center;
  border-radius: 10px;
  border: 1px dashed #007AFF;
  background: rgba(19, 19, 20, 0.02);
  color: #007AFF;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s;
  line-height: 105%;
  min-height: 130px;
}
.physical-cart__create-button::before {
  content: " ";
  display: inline-block;
  background: url(../../../images/basket.svg) center no-repeat;
  position: relative;
  top: 6px;
  right: 5px;
  width: 24px;
  height: 24px;
}
.physical-cart__create-button:hover {
  background: rgba(19, 19, 20, 0.075);
}