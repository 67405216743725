.ineraction__default-button {
  padding: 10px 24px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 105%;
  background: #2886EB;
  border-radius: 10px;
  position: relative;
}